import { css } from '@emotion/core'
import Chart from 'chart.js'
import 'chartjs-plugin-annotation'
import { format } from 'date-fns'
import { Link } from 'gatsby'
import { flatten, isNil, values } from 'ramda'
import React from 'react'
import ReactChartkick from 'react-chartkick'
import { LineChart } from 'react-chartkick'
import { Subscribe } from 'unstated'

import blue from '@material-ui/core/colors/blue'
import green from '@material-ui/core/colors/green'
import grey from '@material-ui/core/colors/grey'
import orange from '@material-ui/core/colors/orange'
import pink from '@material-ui/core/colors/pink'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import AddIcon from '@material-ui/icons/Add'
import MetGoalIcon from '@material-ui/icons/CheckCircle'

import ExceededGoalIcon from '@material-ui/icons/Star'
import PartialGoalIcon from '@material-ui/icons/Timelapse'

import AppBarDatePicker from '../components/app-bar-datepicker'
import Currency from '../components/currency'
import Layout from '../components/layout'
import Loading from '../components/loading'
import { Statistic, StatisticPercentage } from '../components/statistics'

import FiltersContainer from '../containers/FiltersContainer'
import SavingsContainer from '../containers/SavingsContainer'
import SettingsContainer from '../containers/SettingsContainer'
import { isBootstrapped } from '../containers/withLocalStorage'

import { RateFormula } from '../constants'
import {
  calculateAverageSavingsRate,
  calculateSavings,
  calculateSavingsRate,
} from '../formulas'
import {
  findYearEndPeriod,
  formatPercentage,
  getPeriodDisplayText,
  groupTotalsByYear,
  monthlyPeriodsToChartData,
  sortPeriodsByDescending,
  yearlyPeriodsToChartData,
} from '../util'
import withRoot from '../withRoot'

import emptySavingsIcon from '../images/empty-savings.svg'

import { convertTokens, legacyParse } from '@date-fns/upgrade/v2'

ReactChartkick.addAdapter(Chart)

const GOAL_UNMET_COLOR = orange[400]
const GOAL_MET_COLOR = green[500]
const GOAL_UNMET_COLOR_LIGHT = orange[200]
const GOAL_MET_COLOR_LIGHT = green[300]

const PeriodChart = ({ periods = [], current, target, unit = 'month' }) => {
  const annotations = [
    {
      type: 'line',
      mode: 'horizontal',
      scaleID: 'y-axis-0',
      value: (current * 100).toFixed(0),
      borderColor: current >= target ? GOAL_MET_COLOR : GOAL_UNMET_COLOR,
      borderWidth: 2,
      label: {
        backgroundColor:
          current >= target ? GOAL_MET_COLOR_LIGHT : GOAL_UNMET_COLOR_LIGHT,
        enabled: true,
        content: unit === 'month' ? 'YTD' : 'All Time',
        position: 'right',
        yAdjust: 14,
      },
    },
  ]

  if (target) {
    annotations.push({
      type: 'line',
      mode: 'horizontal',
      scaleID: 'y-axis-0',
      value: (target * 100).toFixed(0),
      borderColor: pink[500],
      borderWidth: 2,
      label: {
        backgroundColor: pink[300],
        enabled: true,
        content: 'Goal',
        position: 'left',
        yAdjust: 14,
      },
    })
  }
  const chartOptions = {
    scales: {
      xAxes: [
        {
          type: 'time',
          time: {
            displayFormats: {
              month: 'MMM',
            },
            unit,
          },
        },
      ],
    },
    annotation: {
      annotations,
    },
  }

  const dataSetOptions = {
    borderColor: blue[300],
    pointBackgroundColor: blue[300],
  }

  return (
    <div css={{ marginTop: '24px', marginBottom: '24px' }}>
      <Card>
        <CardHeader
          title="Savings Rate"
          titleTypographyProps={{
            color: 'textSecondary',
            style: { fontSize: 14 },
          }}
        />
        <CardContent>
          <LineChart
            suffix="%"
            library={chartOptions}
            dataset={dataSetOptions}
            data={
              unit === 'month'
                ? monthlyPeriodsToChartData(periods)
                : yearlyPeriodsToChartData(periods)
            }
          />
        </CardContent>
      </Card>
    </div>
  )
}
PeriodChart.defaultProps = {
  periods: [],
  current: 0,
  target: 0,
  unit: 'month',
}

const StashStat = ({ periods }) => {
  const stashAmounts = flatten(periods.map(p => calculateSavings(p.data)))
  const stashed = stashAmounts.reduce((acc, amount) => (acc += amount), 0)

  return (
    <Statistic
      title="Stashed"
      text={<Currency amount={stashed} />}
      textProps={{ 'data-testid': 'stat-stashed' }}
    />
  )
}

const RateGoalStat = props => (
  <StatisticPercentage
    title="Goal"
    {...props}
    textProps={{ 'data-testid': 'stat-goal' }}
  />
)

const RateYtdStat = ({ current, title = 'YTD' }) => {
  return (
    <StatisticPercentage
      title={title}
      value={current}
      textProps={{ 'data-testid': 'stat-ytd' }}
    />
  )
}

const GoalMessage = ({ current, target }) => {
  const wholeCurrent = parseInt((current * 100).toFixed(0), 10)
  const wholeTarget = parseInt((target * 100).toFixed(0), 10)
  const metGoal = wholeCurrent >= wholeTarget
  const diff = formatPercentage(Math.abs(target - current))

  const messageStyle = css`
    width: 100%;
    color: white;
    padding: 12px;
    line-height: 24px;
  `

  const successGoalStyle = css`
    ${messageStyle};
    background: ${GOAL_MET_COLOR};
  `

  const didNotMeetGoalStyle = css`
    ${messageStyle};
    background: ${GOAL_UNMET_COLOR};
  `

  const iconStyle = css`
    vertical-align: middle;
    float: left;
    margin: 0 8px 0 0;
  `

  if (metGoal && wholeCurrent > wholeTarget) {
    return (
      <Typography css={successGoalStyle}>
        <ExceededGoalIcon css={iconStyle} />
        You exceeded your goal by {diff}! Wonderful!
      </Typography>
    )
  } else if (metGoal && wholeCurrent === wholeTarget) {
    return (
      <Typography css={successGoalStyle}>
        <MetGoalIcon css={iconStyle} />
        You've met your goal! Congratulations!
      </Typography>
    )
  } else {
    return (
      <Typography css={didNotMeetGoalStyle}>
        <PartialGoalIcon css={iconStyle} />
        You have not yet met your goal. That's okay, you got this!
      </Typography>
    )
  }
}

const SummaryStats = ({ current, target, hideExactSavings, periods }) => {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={24}>
          {!hideExactSavings && (
            <Grid item xs={12}>
              <StashStat periods={periods} />
            </Grid>
          )}
          <Grid item>
            <RateYtdStat
              current={current}
              title={!!target ? 'YTD' : 'All Time'}
            />
          </Grid>
          {!!target && (
            <Grid item>
              <RateGoalStat value={target} />
            </Grid>
          )}
        </Grid>
      </CardContent>
      {!!target && (
        <CardActions disableActionSpacing css={{ padding: 0 }}>
          <GoalMessage current={current} target={target} />
        </CardActions>
      )}
    </Card>
  )
}

const periodListContainerStyles = css`
  margin-left: -24px;
  margin-right: -24px;
  margin-top: 24px;
  margin-bottom: 48px;
`

const periodListNavStyles = css`
  background-color: ${grey[50]};
  width: 100%;
  padding: 0;
`

const createPeriodFabStyle = css`
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 1;
`

const PeriodListHeading = ({ children }) => (
  <Typography
    color="textSecondary"
    variant="subtitle2"
    gutterBottom
    css={{ marginLeft: 24 }}
  >
    {children}
  </Typography>
)

class PeriodList extends React.Component {
  render() {
    const { periods, rateFormula, hideExactSavings } = this.props

    return (
      <div css={periodListContainerStyles}>
        <PeriodListHeading>Savings Breakdown</PeriodListHeading>
        <List component="nav" css={periodListNavStyles}>
          {sortPeriodsByDescending(periods).map(period => {
            const monthFormatted = isNil(period.month)
              ? ''
              : format(
                  legacyParse(new Date(period.year, period.month, 1)),
                  convertTokens('MM')
                )

            return (
              <PeriodListItem
                key={JSON.stringify(period)}
                hideExactSavings={hideExactSavings}
                period={period}
                rateFormula={rateFormula}
                ListItemProps={{
                  component: Link,
                  to: `/period/edit/#!${period.year}${
                    monthFormatted ? '-' : ''
                  }${monthFormatted}`,
                  state: { year: period.year, month: period.month },
                }}
              />
            )
          })}
        </List>

        <Fab
          color="primary"
          aria-label="Add"
          css={createPeriodFabStyle}
          component={Link}
          to="/period/create"
        >
          <AddIcon />
        </Fab>
      </div>
    )
  }
}
PeriodList.defaultProps = {
  periods: [],
  rateFormula: RateFormula.AFTER_TAX,
  hideExactSavings: false,
}

const YearList = ({ periods, rateFormula, hideExactSavings, onYearClick }) => (
  <div css={periodListContainerStyles}>
    <PeriodListHeading>Yearly Breakdown</PeriodListHeading>
    <List component="nav" css={periodListNavStyles}>
      {sortPeriodsByDescending(periods).map(period => (
        <PeriodListItem
          key={JSON.stringify(period)}
          hideExactSavings={hideExactSavings}
          period={period}
          rateFormula={rateFormula}
          ListItemProps={{
            onClick: () => onYearClick(period.year),
          }}
        />
      ))}
    </List>

    <Fab
      color="primary"
      aria-label="Add"
      css={createPeriodFabStyle}
      component={Link}
      to="/period/create"
    >
      <AddIcon />
    </Fab>
  </div>
)

class PeriodListItem extends React.Component {
  render() {
    const {
      period,
      rateFormula,
      hideExactSavings,
      ListItemProps = {},
    } = this.props

    const savingsRate = formatPercentage(
      calculateSavingsRate(period.data, rateFormula)
    )
    const savings = calculateSavings(period.data, rateFormula)
    const monthFormatted = isNil(period.month)
      ? ''
      : format(
          legacyParse(new Date(period.year, period.month, 1)),
          convertTokens('MM')
        )

    return (
      <>
        <ListItem
          data-testid="btn-period"
          button
          {...ListItemProps}
          ContainerProps={{
            'data-test-period': `${period.year}-${monthFormatted}`,
          }}
        >
          <ListItemText
            primary={getPeriodDisplayText(period.year, period.month)}
            secondary={
              !hideExactSavings && (
                <span data-testid="savings-stash">
                  stashed <Currency amount={savings} />
                </span>
              )
            }
          />
          <ListItemSecondaryAction>
            <Typography
              color="secondary"
              css={{ marginRight: 12 }}
              variant="h6"
              data-testid="savings-rate"
            >
              {savingsRate}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>
      </>
    )
  }
}

const EmptyState = ({ year }) => (
  <Paper
    css={css`
      text-align: center;
      max-width: 480px;
      margin: 56px auto;
      padding: 48px;
    `}
  >
    <img
      src={emptySavingsIcon}
      css={css`
        max-width: 200px;
      `}
      alt="Empty savings for this time period"
    />

    <Typography variant="subtitle1" css={{ marginBottom: '1em' }}>
      Looks like you haven't added any savings yet{year ? ` to ${year}` : ''}.
      Start tracking your savings by adding a new time entry!
    </Typography>

    <Button
      component={Link}
      to="/period/create"
      variant="contained"
      color="primary"
      size="large"
      data-testid="btn-add"
    >
      Add Savings
    </Button>
  </Paper>
)

const SingleYearView = ({
  year,
  periods,
  targetRate,
  rateFormula,
  hideExactSavings,
}) => {
  const filteredPeriods = periods.filter(period => period.year === year)

  const yearEndPeriod = findYearEndPeriod(year, periods)

  const currentRate = calculateAverageSavingsRate(
    yearEndPeriod ? [yearEndPeriod.data] : filteredPeriods.map(p => p.data),
    rateFormula
  )

  if (filteredPeriods.length <= 0) {
    return <EmptyState year={year} />
  }

  return (
    <>
      <SummaryStats
        periods={filteredPeriods}
        current={currentRate}
        target={targetRate}
        hideExactSavings={hideExactSavings}
      />

      <PeriodChart
        periods={filteredPeriods}
        current={currentRate}
        target={targetRate}
      />

      <PeriodList
        periods={filteredPeriods}
        rateFormula={rateFormula}
        hideExactSavings={hideExactSavings}
      />
    </>
  )
}

const MultiYearView = ({
  periods,
  rateFormula,
  onYearClick,
  hideExactSavings,
}) => {
  const periodsByYear = groupTotalsByYear(periods)
  const filteredPeriods = flatten(values(periodsByYear))

  const currentRate = calculateAverageSavingsRate(
    filteredPeriods.map(p => p.data),
    rateFormula
  )

  if (filteredPeriods.length <= 0) {
    return <EmptyState year={null} />
  }

  return (
    <>
      <SummaryStats
        periods={filteredPeriods}
        current={currentRate}
        hideExactSavings={hideExactSavings}
      />

      <PeriodChart
        periods={filteredPeriods}
        current={currentRate}
        unit="year"
      />

      {
        <YearList
          periods={filteredPeriods}
          rateFormula={rateFormula}
          hideExactSavings={hideExactSavings}
          onYearClick={onYearClick}
        />
      }
    </>
  )
}

const IndexPage = ({ location: { pathname } }) => (
  <Subscribe to={[SavingsContainer, SettingsContainer, FiltersContainer]}>
    {(savingsData, settings, filters) => {
      if (!isBootstrapped(savingsData, settings, filters)) {
        return <Loading />
      }

      const isSingleYear = filters.state.year !== null
      const isMultiYear = !isSingleYear
      const yearDisplay = isSingleYear ? filters.state.year.toString() : 'All'

      return (
        <Layout
          title={`${yearDisplay} Savings`}
          appBarContent={<AppBarDatePicker />}
          pathname={pathname}
        >
          {isSingleYear && (
            <SingleYearView
              year={filters.state.year}
              periods={savingsData.state.periods}
              targetRate={savingsData.state.targetRate}
              rateFormula={savingsData.state.rateFormula}
              hideExactSavings={savingsData.state.hideExactSavings}
            />
          )}

          {isMultiYear && (
            <MultiYearView
              periods={savingsData.state.periods}
              rateFormula={savingsData.state.rateFormula}
              hideExactSavings={savingsData.state.hideExactSavings}
              onYearClick={year => filters.setYear(year)}
            />
          )}
        </Layout>
      )
    }}
  </Subscribe>
)

export default withRoot(IndexPage)
